* {
  font-family: Futura, Helvetica, sans-serif;
  box-sizing: border-box;
  color: white;
}

body {
  margin: 0;
  background-color: black;
}

.flex-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  background-repeat: no-repeat;
  /* background-size: 100% 100%; */
  background-size: cover;
  background-position: bottom right;
}

nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 10vh;
  width: 100%;
  color: white;
}

.header {
  font-size: 5rem;
  text-shadow: 2px 4px 4px black;
  text-align: center;
}

.nav-links {
  width: 40%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
}

.nav-btn {
  white-space: normal;
}

.MuiButton-label {
  text-shadow: 2px 4px 4px black;
}

.MuiButton-label:hover {
  color: aqua;
  text-shadow: none;
}

/* .nav-btn a {
  padding-left: 0.5rem;
  position: relative;
  top: 1px;
  text-decoration: none;
  font-size: 14px;
  text-shadow: 2px 4px 4px black;
}

.nav-btn a:hover {
  color: aqua;
  text-shadow: none;
} */

.header-hyperlink {
  text-decoration: none;
}

.intro {
  color: white;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.intro p {
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

/* Slideshow container  */

.slideshow-container {
  max-width: 1000px;
  height: 10rem;
  position: relative;
  margin: 8rem auto;
}

/* Hide the images by default */
.mySlides {
  display: none;
}

.mySlides p:first-child {
  width: 100%;
  height: auto;
  border-radius: 3px;
  font-family: 'Times New Roman', Times, serif;
  font-size: 2rem;
  font-style: italic;
  text-align: center;
  padding: 0;
}

.quote-name {
  float: right;
  margin-right: 1rem;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

/* projects page */

.projects {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(400px, 2fr)); */
  grid-template-columns: 50% 50%;
  gap: 3rem;
  margin: 3rem 0 3rem 4rem;
}

.project {
  width: 80%;
  height: 100%;
  margin: 1rem;
}

.project-img-div {
  width: 100%;
}

.project-img {
  width: 100%;
  /* min-width: 100%; */
  height: 30rem;
  border-radius: 4px;
}

.project-text-card {
  text-align: center;
  width: 100%;
  border-radius: 5px;
  font-size: 1.25rem;
}

.website-link h2 {
  padding-bottom: 30px;
}

.website-link h2:hover {
  color: aqua;
}

.github-link {
  font-size: 3rem;
  padding: 1rem;
}

.github-link > a > svg > path:hover {
  color: aqua;
}

.section-title {
  padding-left: 50px;
}

/* contact form */

.contact-div {
  padding-bottom: 100px;
  /* background-color: #00667a; */
  width: 90%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: auto;
  margin-left: auto;
}

.contact-me-header {
  font-family: 'Poppins', sans-serif, 'arial';
  font-weight: 600;
  font-size: 45px;
  text-align: center;
  text-shadow: 2px 4px 4px black;
}

h4 {
  font-family: 'Roboto', sans-serif, 'arial';
  font-weight: 400;
  font-size: 20px;
  color: #9b9b9b;
  line-height: 1.5;
}

/* .email-link {
  text-align: center;
  font-size: 30px;
}

.email-link a {
  text-decoration: none;
}

.email-link a:hover {
  color: aqua;
  background-color: turquoise;
} */

/* ///// inputs /////*/

input:focus ~ label,
textarea:focus ~ label,
input:valid ~ label,
textarea:valid ~ label {
  font-size: 1em;
  /* color: #999; */
  top: -5px;
  -webkit-transition: all 0.225s ease;
  transition: all 0.225s ease;
}

::placeholder {
  font-style: italic;
  color: #999;
}

.styled-input {
  float: left;
  width: 293px;
  margin: 1rem 0;
  position: relative;
  border-radius: 4px;
}

.styled-input label {
  color: #999;
  padding: 1.3rem 30px 1rem 30px;
  position: absolute;
  top: 10px;
  left: 0;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  pointer-events: none;
}

.styled-input.wide {
  width: 650px;
  max-width: 100%;
}

input,
textarea {
  padding: 30px;
  border: 0;
  height: 2em;
  width: 90%;
  font-size: 1.5rem;
  background-color: white;
  color: black;
  border-radius: 4px;
  margin: 1rem;
}

input:focus,
textarea:focus {
  outline: 0;
}

input:focus ~ span,
textarea:focus ~ span {
  width: 100%;
  -webkit-transition: all 0.075s ease;
  transition: all 0.075s ease;
}

textarea {
  width: 95%;
  min-height: 15em;
}

.input-container {
  width: 650px;
  max-width: 100%;
  margin: 20px auto 25px auto;
}

.submit-btn {
  width: 12rem;
  margin-top: 15px;
  padding: 7px 35px;
  margin-left: 0.85rem;
  border-radius: 4px;
  display: inline-block;
  background-color: #4b8cfb;
  color: white;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06), 0 2px 10px 0 rgba(0, 0, 0, 0.07);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.submit-btn:hover {
  transform: translateY(1px);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.09);
}

input[type='checkbox'] + label {
  color: #ccc;
  font-style: italic;
}

input[type='checkbox']:checked + label {
  color: #f00;
  font-style: normal;
}

div.info-and-form {
  background-color: white;
}

.contact-success {
  background-color: rgb(71, 155, 113);
  text-align: center;
  padding: 1rem 1rem;
  line-height: normal;
  width: 30%;
  margin: auto;
  border-radius: 4px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.contact-success p {
  color: white;
}

/* resume page */

.resume-link {
  font-size: 3rem;
  text-align: center;
  padding: 3rem;
}

.resume-link a {
  text-decoration: none;
  font-style: italic;
}

.resume-link a:hover {
  color: aqua;
  letter-spacing: 0.5rem;
}

.resume-pages {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* .resume-pages img {
  display: block;
  margin-left: auto;
  margin-right: auto;
} */

/* footer */
.footer {
  display: flex;
  justify-content: center;
  justify-content: space-between;
  /* margin-top: 2rem; */
  padding: 2rem;
  bottom: 0;
  left: 0;
  width: 100%;
  /* box-shadow: 0 3px 10px rgb(0 0 0 / 0.7); */
  text-shadow: 2px 4px 4px black;
}

.footer-icons {
  display: flex;
  flex: wrap;
  justify-content: space-evenly;
  margin-top: 1.5rem;
}

.footer-icons a {
  padding: 2rem;
}

.footer-icons a svg:hover {
  fill: aqua;
  cursor: pointer;
}

.copyright {
  /* text-align: right; */
  display: flex;
  justify-content: space-between;
  color: white;
  font-size: 2rem;
}

.copyright-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.copyright h4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1.7rem;
  padding-left: 0.5rem;
  color: white;
}

/* media queries */
@media (max-width: 3500px) {
  .projects {
    display: flex;
  }
  .project-img {
    width: 100%;
    height: 30rem;
  }
}

@media (max-width: 2799px) {
  .projects {
    display: grid;
  }
  .project-img {
    width: 100%;
    height: 40rem;
  }
}

@media (max-width: 2400px) {
  .project-img {
    width: 90%;
    height: 35rem;
  }
}

@media (max-width: 2200px) {
  .project-img {
    width: 100%;
  }
}

@media (max-width: 1900px) {
  .project-img {
    height: 30rem;
  }
}

@media (max-width: 1750px) {
  .project-img {
    height: 25rem;
  }
}

@media (max-width: 1400px) {
  .project-img {
    height: 20rem;
  }
}

@media (max-width: 1260px) {
  .projects {
    margin-left: 2rem;
  }
}
@media (max-width: 1160px) {
  .project-img {
    height: 18rem;
  }
}
@media (max-with: 1100px) {
  .project-img {
    height: 15rem;
  }
}

@media (max-width: 1024px) {
  .projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
  }
  .project {
    margin: 0;
  }
  .project-img {
    height: auto;
  }
  .resume-pages img {
    width: 95%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 768px) {
  .flex-wrapper {
    background-size: 200% 100%;
  }
  .header {
    margin: 0;
  }
  p.about-paragraph {
    margin-top: 0;
    padding-top: 0;
  }
  .slideshow-container {
    margin-top: 5rem;
    margin-bottom: 0;
  }
  .mySlides p:first-child {
    font-size: 1.25rem;
  }
  .submit-btn {
    font-size: 1rem;
  }
  .projects {
    margin: 0;
  }
  .project {
    width: 100%;
  }
  .styled-input {
    width: 100%;
  }
  .footer {
    justify-content: space-between;
  }
}

@media (max-width: 576px) {
  .header {
    font-size: 3rem;
  }
  .nav-btn {
    align-items: flex-end;
  }
  p.about-paragraph {
    font-size: 1rem;
  }
  .email-link {
    font-size: 20px;
  }
  .name-input,
  .email-input,
  textarea {
    width: 94%;
  }
  .submit-btn {
    width: 30%;
    font-size: 0.9rem;
    padding: 0.25rem 1rem;
  }
  .footer {
    justify-content: space-around;
    padding: 1rem;
  }
  .footer-icons {
    /* flex-direction: column; */
    font-size: 2rem;
    /* justify-content: space-around; */
  }
  .footer-icons a {
    padding: 1rem;
  }
  .quote-name {
    font-size: 0.75rem;
  }
}

@media (max-width: 424px) {
  .projects {
    margin: 0;
  }
  .submit-btn {
    /* float: right; */
  }
  .textarea {
    width: 90%;
  }
  .contact-form {
    width: 97%;
  }
}

@media (max-width: 320px) {
  .MuiButton-label {
    font-size: 0.75rem;
  }
  .nav-btn a {
    font-size: 0.8rem;
  }
  .input,
  textarea {
    height: auto;
    width: 90%;
    font-size: 1.25rem;
  }
  .contact-me-header {
    font-size: 40px;
  }
  .name-input,
  .email-input,
  textarea {
    width: 92%;
  }
  .submit-btn {
    width: 40%;
    margin-right: 1rem;
  }
  .copyright svg {
    height: 1.5rem;
  }
  .copyright h4 {
    font-size: 1rem;
    margin-bottom: 1rem;
    padding-left: 0.25rem;
  }
}

@media (max-width: 281px) {
  .MuiButton-label {
    font-size: 0.5rem;
  }
}
